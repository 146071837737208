import React, { Component } from "react"
import "./App.css"
import YesYepNeon from "./components/yesyepneon"

class App extends Component {
	render() {
		return (
			<div className="App center">
				<YesYepNeon />
			</div>
		)
	}
}

export default App
